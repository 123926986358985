// Here you can add other styles
.previewImage {
  max-height: 300px;
  max-width: 100%;
}

.tableImage {
  max-height: 100px;
  max-width: 100px;
}

.activeDay {
  background: #1b9e3e;
}

.hasSlots {
  background: #555;
  color: #fff;
}

.hasFreeSlots {
  background: #1b9e3e;
  color: #fff;
}
