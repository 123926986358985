// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";
@import '~react-confirm-alert/src/react-confirm-alert.css';
@import "~react-datetime/css/react-datetime.css";
@import "texteditors";
// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";
